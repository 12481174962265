import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InternetFacility } from '../interfaces/InternetFacility';
import { Alert, Backdrop, Box, Button, Card, CardContent, Checkbox, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled, tableCellClasses } from '@mui/material';
import { HpsdConfigBodyRequest } from '../interfaces/HpsdConfigBodyRequest';
import { hpsdConfigCallbackRequest, hpsdConfigRequest } from '../services/apiSys';
import handleError from '../utils/handleError';
import { VlanType } from '../utils/EnumUtils';
import { Service, ValueElement } from '../interfaces/HpsdConfigCallback';
import CircularProgressIndeterminateWithLabel from './CircularProgressIndeterminateWithLabel';

type Props = {
  dataFacility: InternetFacility;
};

type VlanRow = {
  id: number;
  type: VlanType;
  value: string | undefined;
  newValue?: string[];
};

const motives = [
  'Porta Ocupada',
  'Rede Saturada',
  'Distância',
  'Porta com defeito',
  'Acerto de Cadastro',
  'Alívio de Rede',
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#461E5F',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.background.paper,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ManobraVlanDisplay = ({ dataFacility }: Props) => {
  const [vlanSelected, setVlanSelected] = useState<readonly number[]>([]);
  const [vlanTableRows, setVlanTableRows] = useState<VlanRow[]>([]);
  const [motive, setMotive] = useState<string>('');
  const [resultId, setResultId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [warning, setWarning] = useState<string>('');
  const notificationRef = useRef<HTMLDivElement>(null);
  const [manobraDisable, setManobraDisable] = useState(true);
  const [svlanNewValue, setSvlanNewValue] = useState<string>('');
  const [cvlanNewValue, setCvlanNewValue] = useState<string>('');

  const geralCharacteristic = useCallback(() => {
    return dataFacility.serviceCharacteristic.find((char) => char.name === 'GERAL')?.value;
  }, [dataFacility])(); // Invoking the function immediately to get the initial values

  const executeManobra = async () => {
    try {

      const body: HpsdConfigBodyRequest = {
        service: {
          id: dataFacility.id,
          category: "ManobraRecursos.MNVL14",
          serviceSpecification: "Internet"
        },
        fields: [
          { name: "ManobraSVLAN", value: svlanNewValue !== '' ? 'SIM' : 'NAO' },
          { name: "ManobraCVLAN", value: cvlanNewValue !== '' ? 'SIM' : 'NAO' },
          { name: "SVLAN_PARA", value: svlanNewValue },
          { name: "CVLAN_PARA", value: cvlanNewValue },
          { name: "NaturezaOrdem", value: "Manobra" },
          { name: 'REALLOCATION_REASON', value: motive },
          // { name: 'REALLOCATION_REASON_TEMP', value: motive },
        ]
      };
      console.log(body);
      const resp = await hpsdConfigRequest(body);
      setResultId(resp.data?.id_result);
      console.log('Manobra Iniciada');
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  /**
   * Clear alert messages 
   */
  const clearMsgs = () => {
    setError('');
    setSuccess('');
    setWarning('');
  };
  /**
   * Build IpTableRows
   */
  useEffect(() => {
    const vlanData = dataFacility.serviceCharacteristic.find((service) => service.name === "DADOS")?.value;

    if (vlanData) {
      setVlanTableRows([
        { id: 0, type: VlanType.CVLAN, value: vlanData['VLAN_CUSTOMER'], newValue: [''] },
        { id: 1, type: VlanType.SVLAN, value: vlanData['VLAN_NETWORK'], newValue: [''] },
      ]);
    }
  }, [dataFacility]);

  /**
   * Scroll to the notification
   */
  useEffect(() => {
    if (notificationRef.current) {
      notificationRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, [error, success]);

  /**
   * Request callback response from the config
   */
  useEffect(() => {
    if (resultId) {
      //Method to ping the Hpsd Config Callback
      const pingConfigStatus = async () => {
        try {
          const resp = await hpsdConfigCallbackRequest(resultId);
          if (resp.data && Object.keys(resp.data).length > 0) {
            // If the config is finished, clear the interval
            clearInterval(intervalId);
            if (resp.data.event.serviceOrder.serviceOrderItem) {
              const service = resp.data.event.serviceOrder.serviceOrderItem[0].service;
              const state = resp.data.event.serviceOrder.state;

              if (service.category === 'ManobraRecursos.MNVL05') {
                setLoading(false);
                if (state === 'completed' && checkVlanAvailable(service)) {
                  setManobraDisable(false);
                  setSuccess('VLANs disponíveis.');
                } else {
                  setWarning(`${resp.data.event.serviceOrder.description ?? 'Nenhuma VLAN disponível.'}`);
                }
              } else if (service.category === 'ManobraRecursos.MNVL14') {
                setLoading(false);
                if (state === 'completed') {
                  setSuccess('Manobra realizada com sucesso.');
                } else {
                  setError(`${resp.data.event.serviceOrder.description ?? 'Manobra finalizada com erro.'}`);
                }

              }
            } else {
              setError('Timeout - não recebeu resposta do callback HPSD.');
              setLoading(false);
            }
          }
        } catch (error) {
          handleError({ error, setError });
          clearInterval(intervalId);
          setLoading(false);
        }
      };

      // Set up a periodic ping every 5 seconds to check if the Config Task is already finished and get the result
      const intervalId = setInterval(async () => {
        // Check task status initially
        pingConfigStatus();
      }, 5000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [resultId]);

  /**
   * Handle checkbox of VLAN Table
   * @param event 
   * @param id Row ID
   */
  const handleChechboxManobraClick = (event: React.MouseEvent<unknown>, id: number) => {
    let newSelected: readonly number[] = [];

    if (vlanSelected.includes(id)) {
      newSelected = vlanSelected.filter((selected) => selected !== id);
    } else {
      newSelected = newSelected.concat(vlanSelected, id);
    }

    setVlanSelected(newSelected);
  };

  /**
   * Build the request body to HPSD MNVL05 service
   * @returns MNVL05 request body
   */
  const buildMNVL05RequestBody = () => {
    let fields = [
      { name: 'ManobraSVLAN', value: 'NAO' },
      { name: 'ManobraCVLAN', value: 'NAO' },
    ];

    vlanSelected.forEach((id) => {
      if (vlanTableRows[id].type === VlanType.CVLAN) {
        fields.find((item) => item.name === 'ManobraCVLAN')!.value = 'SIM';
      }
      if (vlanTableRows[id].type === VlanType.SVLAN) {
        fields.find((item) => item.name === 'ManobraSVLAN')!.value = 'SIM';
      }
    });

    const body: HpsdConfigBodyRequest = {
      service: {
        id: dataFacility.id,
        category: "ManobraRecursos.MNVL05",
        serviceOrderItem: "Internet",
        serviceSpecification: 'Internet'
      },
      fields: [
        ...fields,
        { name: 'NaturezaOrdem', value: 'Manobra' }
      ]
    };

    return body;
  };

  /**
   * Execute MNVL05 request
   */
  const handleMNVL05Button = async (): Promise<void> => {
    clearMsgs();
    setLoading(true);
    try {
      const body = buildMNVL05RequestBody();
      const resp = await hpsdConfigRequest(body);
      setResultId(resp.data?.id_result);
    } catch (error) {
      handleError({ error, setError });
    }
  };

  /**
   * Check if the callback response contains a SVLAN/CVLAN available and set the values in the MenuItem component
   * @param service 
   * @returns
   */
  const checkVlanAvailable = (service: Service) => {
    const svlanCharCapacityAmount = service.serviceCharacteristic.find((char) => char.name === 'appliedCapacityAmountSVLAN');
    const cvlanCharCapacityAmount = service.serviceCharacteristic.find((char) => char.name === 'appliedCapacityAmountCVLAN');
    const svlanCapacityAmount = svlanCharCapacityAmount ? +svlanCharCapacityAmount.value : undefined;
    const cvlanCapacityAmount = cvlanCharCapacityAmount ? +cvlanCharCapacityAmount.value : undefined;

    let isVlanAvailable = false;

    if (svlanCapacityAmount && svlanCapacityAmount > 0) {
      const resourcesSvlan = service.serviceCharacteristic.find((char) => char.name === 'resourcesSVLAN')?.value as ValueElement[];
      const svlans = resourcesSvlan.map((svlan) => svlan.id);

      setVlanTableRows(prevRows => {
        return prevRows.map(row => {
          if (row.type === VlanType.SVLAN && svlans) {
            return { ...row, newValue: svlans };
          }
          return row;
        });
      });
      // setSvlanOptions(resourcesSvlan);
      isVlanAvailable = true;
    }
    if (cvlanCapacityAmount && cvlanCapacityAmount > 0) {
      const resourcesCvlan = service.serviceCharacteristic.find((char) => char.name === 'resourcesCVLAN')?.value as ValueElement[];
      const cvlans = resourcesCvlan.map((cvlan) => cvlan.id);

      setVlanTableRows(prevRows => {
        return prevRows.map(row => {
          if (row.type === VlanType.CVLAN && cvlans) {
            return { ...row, newValue: cvlans };
          }
          return row;
        });
      });
      // setCvlanOptions(resourcesCvlan);
      isVlanAvailable = true;
    }

    return isVlanAvailable;
  };

  /**
   * Handle the Manobra button to execute the HPSD MNVL14 request
   */
  const handleSubmitManobra = async () => {
    clearMsgs();
    setResultId('');
    let isValid = true;
    try {
      if (vlanSelected.length === 0) {
        setError('Selecione pelo menos uma VLAN para Manobrar.');
        isValid = false;
      }
      if (!svlanNewValue && !cvlanNewValue) {
        setError('Selecione um novo valor para a VLAN selecionada.');
        isValid = false;
      }
      if (!motive) {
        setError('Selecione um motivo');
        isValid = false;
      }

      if (isValid) {
        setLoading(true);
        executeManobra();
      }
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  return (
    <Box width={'100%'}>
      <Stack sx={{ width: '100%', marginTop: 1 }} spacing={2}>
        <div ref={notificationRef}>
          {error && (
            <Alert severity="error" variant='filled' onClose={() => { setError(''); }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" variant='filled' onClose={() => { setSuccess(''); }}>
              {success}
            </Alert>
          )}
          {warning && (
            <Alert severity="warning" variant='filled' onClose={() => { setWarning(''); }}>
              {warning}
            </Alert>
          )}
        </div>
      </Stack>
      {geralCharacteristic && (
        <Box
          width="100%"
          marginTop={1}
        >
          <Card>
            <CardContent sx={{
              '& .MuiTextField-root': { m: 1 },
              display: 'flex',
              justifyContent: 'space-around'
            }}>
              <TextField
                label="Tipo Acesso"
                value={geralCharacteristic.TIPO_ACESSO}
                variant='standard'
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
              <TextField
                label="Rede"
                value={geralCharacteristic.PLACE_RESERVED?.network_owner}
                variant='standard'
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
              <TextField
                label="Serviço"
                value={geralCharacteristic.TIPO_SERVICO}
                variant='standard'
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
              <TextField
                label="Razão Social"
                value={geralCharacteristic.RAZAO_SOCIAL}
                variant='standard'
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
            </CardContent>
          </Card>
          {vlanTableRows && (
            <>
              <TableContainer component={Paper} sx={{ marginTop: 1 }}>
                <Typography variant='h6' textAlign={'center'} sx={{ background: '#461E5F', color: '#fff' }}>
                  Configurações de SVLAN e CVLAN
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>VLAN</StyledTableCell>
                      <StyledTableCell align='center'>Atual</StyledTableCell>
                      <StyledTableCell align='center'>Desejado</StyledTableCell>
                      <StyledTableCell align='center'>Manobra</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vlanTableRows.map((row, index) => {
                      const isItemSelected = vlanSelected.includes(row.id);
                      return (
                        <StyledTableRow
                          hover
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <StyledTableCell>{row.type}</StyledTableCell>
                          <StyledTableCell align='center'>{row.value}</StyledTableCell>
                          {/* <StyledTableCell align='center'>{row.newValue}</StyledTableCell> */}
                          <StyledTableCell align='center'>
                            {row.type === VlanType.SVLAN && (
                              <Select
                                value={svlanNewValue}
                                // displayEmpty
                                fullWidth
                                onChange={(e) => setSvlanNewValue(e.target.value)}
                              >
                                {row.newValue?.map((newVlan) => (
                                  <MenuItem
                                    disabled={newVlan === ''}
                                    key={newVlan}
                                    value={newVlan}
                                  >
                                    {newVlan === '' ? (<em>Nenhum</em>) : newVlan}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                            {row.type === VlanType.CVLAN && (
                              <Select
                                value={cvlanNewValue}
                                // displayEmpty
                                fullWidth
                                onChange={(e) => setCvlanNewValue(e.target.value)}
                              >
                                {row.newValue?.map((newVlan) => (
                                  <MenuItem
                                    disabled={newVlan === ''}
                                    key={newVlan}
                                    value={newVlan}
                                  >
                                    {newVlan === '' ? (<em>Nenhum</em>) : newVlan}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <Checkbox
                              color='primary'
                              checked={isItemSelected}
                              required
                              onClick={(event) => handleChechboxManobraClick(event, row.id)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {vlanSelected.length > 0 && (
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    marginY={1}
                  >
                    <Button
                      className='button'
                      variant='contained'
                      onClick={handleMNVL05Button}>
                      Pesquisar
                    </Button>
                  </Box>
                )}
              </TableContainer>
            </>
          )}
          <Card className='card' sx={{ marginTop: 1 }}>
            <CardContent>
              <FormControl fullWidth margin='normal'>
                <InputLabel>Motivo</InputLabel>
                <Select
                  value={motive}
                  label="Motivo"
                  required
                  onChange={(e) => setMotive(e.target.value as string)}
                >
                  {motives.map((motive) => (
                    <MenuItem
                      key={motive}
                      value={motive}
                    >
                      {motive}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
          <Box
            display={'flex'}
            justifyContent={'center'}
            marginTop={1}
          >
            <Button
              className='button'
              variant='contained'
              onClick={handleSubmitManobra}
              disabled={manobraDisable}
            >
              Manobrar
            </Button>
          </Box>
        </Box>
      )}
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
          open={loading}
        >
          {/* <CircularProgress color="inherit" /> */}
          {/* <CircularWithValueLabel maxSecondsTime={180} interval={5000} /> */}
          <CircularProgressIndeterminateWithLabel maxSecondsTime={600} descriptions={
            ['Processo em andamento...', 'Processo demorando mais que o normal...', 'Provável falha na consulta por timeout...']
          }
          />
        </Backdrop>
      )}
    </Box>
  );
};

export default ManobraVlanDisplay;

